.body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .thank-you-container {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .thank-you-heading {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .thank-you-text {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .thank-you-image {
    width: 300px;
    height: auto;
    margin-top: 20px;
    border-radius: 8px;
  }